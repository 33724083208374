<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>DATA ARTIKEL </strong><small>Tambah Data Baru</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="inputArtikel">
            <CRow>
              <CCol sm="12">
                <CInput
                  v-model="judul_berita"
                  label="Judul Artikel"
                  placeholder="Judul Artikel"
                />
              </CCol>

              <CCol sm="6">             
                <div class="form-group">
                <label>Jenis Artikel</label>
                  <select class="form-control" v-model="rubrik">
                    <option value="" disabled selected>Pilih Jenis</option>
                    <option v-for="r in rubriks" :value="r.id_rubrik">{{r.nama_rubrik}} </option>
                  </select>
                </div>
              </CCol>

              <CCol sm="6">
                <div class="form-group">
                  <label>Foto Artikel </label><br/>                  
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" @change="onFileChange2($event)" id="inputGroupFile02"
                    :placeholder="placeholder" aria-describedby="inputGroupFileAddon01">
                    <label class="custom-file-label" for="inputGroupFile01">{{placeholder}}</label>
                  </div>
                </div>  
              </CCol>

              <CCol sm="12">
                <CTextarea
                  v-model="isi_berita"
                  label="Isi Artikel"
                  placeholder="Isi Artikel"
                />  
              </CCol>

            </CRow>          
              <div class="form-actions">
                <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                <CButton color="secondary" to="/artikel">Batal</CButton>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      :show.sync="modalError"
    >
      <b>Error</b>! Data gagal diinput.
    </CModal>
   
  </div>
</template>
<script>
import Datepicker from 'vuejs-datepicker';
import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {Datepicker },
  name: 'Forms',
  data () {
    return {
      judul_berita : "",
      
      isi_berita : "",
      gambar_berita : "",
      gambar_berita_text : "",
      rubrik : "",
      placeholder : "Pilih File",
      rubriks : [], // Must be an array reference!
      selected : [], // Must be an array reference!
      show: true,
      modalError:false,
    }
  },
  mounted(){
  this.loadData();
  },

  methods: {

    loadData: function(event){

      axios.get(process.env.VUE_APP_BASE_URL+"rubriks")
        .then((response) => {
              this.rubriks=response.data;
        });
    },

    onFileChange2(event) {
      var fileData = event.target.files[0];
      this.gambar_berita_text = fileData.name;
      this.gambar_berita = fileData;
      this.placeholder = fileData.name;
      // alert(fileData.name);
    },

    inputArtikel: function(event){
     
      const formData = new FormData();

      // formData.append('id_berita', this.$route.params.id_berita)
      formData.append('gambar_berita', this.gambar_berita, this.gambar_berita.name) 
      formData.append('gambar_berita_text', this.gambar_berita.name)
      formData.append('judul_berita', this.judul_berita)
      formData.append('id_rubrik', this.rubrik)
      formData.append('isi_berita', this.isi_berita)
      // formData.append('tgl_posting', this.tgl_posting)
      // alert(JSON.stringify(formData));
      axios.post(process.env.VUE_APP_BASE_URL+"berita", formData )
        .then((response) => {
          // alert(JSON.stringify(response.data));
            if(response.data.status==='success'){
              this.$router.push('/artikel');
            }
            else{
              this.modalError=true;
            }           
        })
    }
  }
}
</script>